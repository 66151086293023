import { Column, Row } from "@react-tiny-grid/core"
import React from "react"
import { Zoom } from "react-reveal"
import styled from "styled-components"

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffffcc;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
  padding: 25px 15px;
  margin: 5px;
  border-radius: 10px;
  height: 100%;
  text-align: center;
  transition: transform 0.5s ease-in-out;

  & > h2 {
    color: #1c365e;
    font-size: 1.2em;
  }

  &:hover {
    color: #fff;
    background-color: #1c365e;
    transform: scale(1.1);

    & > h2 {
      color: #fff;
    }
  }
`

const whyUs = [
  [
    {
      title: "Cross Platform",
      text: "Feel free to use Eviquire on Windows, Linux and Mac!",
    },
    {
      title: "Hybrid License",
      text: "Buy one license and enjoy Eviquire Everywhere! Both in your Desktop or as Cloud Application.",
    },
    {
      title: "Virtual Machines Ready",
      text: "We do advise to run Eviquire in a linux VM so that if required, you can easily attach the entire VM to the legal case.",
    },
  ],
  [
    {
      title: "Evidence Forensic Value",
      text: "Enjoy Forensic Package created by Eviquire is going well over the Best Practices in terms of Evidence Integrity.",
    },
    {
      title: "Audio/Video/Network",
      text: "To provide the strongest integrity confirmation, the entire acquisition session is video and network recorded.",
    },
    {
      title: "Multi Blockchain Timestamp",
      text: "Each Forensic Package created by Eviquire is timestamped in real time using multiple blockchains.",
    },
  ],
  [
    {
      title: "Forensic Reports creation",
      text: "Automatic Forensic Reports are created at any stage of the Acquisition.",
    },
    {
      title: "No Acquisition Environment",
      text: "No need create and maintain a complex acquisition environments, Eviquire does the heavy job for you!",
    },
    {
      title: "Anti Extension Fingerprinting",
      text: "Since Eviquire is a Native Forensic Browser, it's not vulnerable to Extension Fingerprinting",
    },
  ],
]

export default function WhyEviquireSection() {
  return (
    <>
      <h1 className="head">Why EVIQUIRE</h1>
      <br />
      <h1 className="sub-head">
        Eviquire applies a technology-first approach to Web Forensics with some
        innovative benefits:
      </h1>
      <br /> <br />
      {whyUs.map((rows, rowIndex) => {
        return (
          <Row key={`whyUs-${rowIndex}`} breakpoints={[769]} className="h100">
            {rows.map((column, colIndex) => (
              <Column key={`whyUs-${rowIndex}-${colIndex}`}>
                <Zoom>
                  <Box>
                    <h2>{column.title}</h2> <br />
                    <p>{column.text}</p>
                  </Box>
                </Zoom>
              </Column>
            ))}
          </Row>
        )
      })}
      <br /> <br /> <br />
    </>
  )
}
